/* The container needed to position the dropdown content */

.index__dropdown___2IEZb {
  position: relative;
  display: inline-block;
}

/* Dropdown Menu (Hidden by Default) */

.index__dropdownMenu___33iq9 {
  display: none;
  position: absolute;
  background-color: var(--color-marinaBlue-20);
  border-radius: 0.25rem;
  z-index: 10;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  max-width: 470px;
  min-width: 170px;
}

/* Dropdown placement */

.index__bottomCenter___1bEJO {
  top: 100%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.index__bottomLeft___1msO8 {
  left: 0;
  top: 100%;
}

.index__bottomRight___1SScT {
  right: 0;
  top: 100%;
}

.index__top___337Rv {
  bottom: 100%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.index__topLeft___fNxvV {
  left: 0;
  bottom: 100%;
}

.index__topRight___SKhlZ {
  right: 0;
  bottom: 100%;
}

/* Links inside the dropdown */

.index__dropdownMenuItem___DtSpV {
  display: block;
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 140%;
  line-height: 1.5;
  color: var(--color-marinaBlue-90);
  white-space: nowrap;
  cursor: pointer;
}

/* Change color of dropdown links on hover */

.index__dropdownMenuItem___DtSpV:hover {
  background-color: var(--color-marinaBlue-30);
}

/* Show the dropdown menu on hover */

.index__dropdown___2IEZb:hover .index__dropdownMenuOnHover___22kXb,
.index__dropdownMenuOnClick___3JWky {
  display: block;
}

.index__disabled___19en5 {
  opacity: 0.3;
}

.index__disabled___19en5:hover {
  background-color: transparent;
}
