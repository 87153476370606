.index__outerBar___1dHMT {
  height: 4px;
  width: 100%;
  background-color: var(--color-basaltGray-40);
}

.index__innerBar___Drr1Q {
  height: 100%;
  width: 100%;
  transform-origin: left;
  transition-property: transform;
  transition-duration: 300ms;
  background-color: var(--color-marinaBlue-90);
  will-change: transform;
}
