.index__accordionRoot___1VgzB {
  display: flex;
  flex-direction: column;
}

.index__headerWrapper___2m7lt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.index__headerWrapper___2m7lt:focus {
  outline: none;
}

.index__headerContent___3lByc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  grid-gap: 8px;
  gap: 8px;
}

.index__icon___i4cj3 {
  transition: all 0.5s;
}

.index__panelWrapper___3wZmV {
  will-change: opacity, height;
  overflow: hidden;
}
