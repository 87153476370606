.index__tooltipWrapper___10FKG {
  display: inline-block;
  position: relative;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.index__tooltipWrapper___10FKG svg {
  /* Pointer events none removes the default tooltip that shows the SVG title*/
  pointer-events: none;
}

/* Tooltip text */

.index__tooltipWrapper___10FKG .index__tooltipInner___PuhG5 {
  visibility: hidden;
  overflow: hidden;
  text-align: center;
  border-radius: 0.25rem;
  padding: 1rem;
  max-width: 364px;
  width: -webkit-max-content;
  width: max-content;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 10;
}

/* Show the tooltip text when you mouse over the tooltip container */

.index__tooltipWrapper___10FKG:hover .index__tooltipInner___PuhG5 {
  visibility: visible;
}

.index__dark___2gTgL {
  background-color: var(--color-marinaBlue-10);
}

.index__light___2uNFr {
  background-color: var(--color-marinaBlue-20);
}

/* TOOLTIP POSITION */

/* BOTTOM */

.index__bottom___1qVgn {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.index__bottomLeft___3cYSu {
  top: 100%;
  right: 100%;
}

.index__bottomRight___2u-j5 {
  top: 100%;
  left: 100%;
}

.index__bottomRightIn___26YfJ {
  top: 100%;
  right: 0;
}

.index__bottomLeftIn___P_0yP {
  top: 100%;
  left: 0;
}

/* LEFT */

.index__left___1D4tn {
  right: 105%;
  top: 50%;
  transform: translateY(-50%);
}

.index__leftBottom___2-UK1 {
  top: 100%;
  right: 100%;
}

.index__leftTop___1vQVd {
  bottom: 100%;
  right: 100%;
}

/* RIGHT */

.index__right___3jDyr {
  left: 105%;
  top: 50%;
  transform: translateY(-50%);
}

.index__rightBottom___3QuVC {
  top: 100%;
  left: 100%;
}

.index__rightTop___3iSh3 {
  bottom: 100%;
  left: 100%;
}

/* TOP */

.index__top___dlSMd {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.index__topLeft___1Ya0v {
  bottom: 100%;
  right: 100%;
}

.index__topRight___1TEeK {
  bottom: 100%;
  left: 100%;
}
