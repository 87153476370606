.index__container___zoo-G {
  width: 100%;
  position: relative; /* To be able to apply the overlay when disabled */
  border-radius: 0.5rem;
  padding: 1rem;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content; /* To guarantee that the content does not overflow the container */
  /* TODO: This should be replaced with the final color
     as soon as we have it on the specs, since the one we have
     corresponds to the old scheme color. */
  box-shadow: 0px 2px 4px rgba(0,0,0, 0.3);
  background: rgba(var(--color-marinaBlue-20-rgb), 0.5);
}

.index__disabled___UuBJW {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background: rgba(var(--color-marinaBlue-10-rgb), 0.4);
}

@media only screen and (min-width: 1024px) {
  .index__container___zoo-G {
    padding: 1.5rem;
  }
}

.index__header___WbQ24 {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  height: 42px; /* There is no tailwind for this size */
}

.index__textContainer___3cHUm {
  font-size: 1.5rem;
  line-height: 140%;
  line-height: 2rem;
  white-space: nowrap;
  color: var(--color-marinaBlue-90);
  width: 100%;
}

.index__textSeparator___3nL3A {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.index__actionsContainer___p4Kia {
  display: flex;
  flex-direction: row;
}

.index__actionContainer___1Krk2 {
  padding-left: 0.5rem;
  white-space: nowrap;
}
