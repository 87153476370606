.index__wrapper___2eyty {
  display: flex;
  align-items: center;
}

.index__input___1SDmg {
  /* Has an input for accessibility */
  /* Visually hides it to style the slider */
  height: 0;
  width: 0;
  opacity: 0;
}

/* Slider Styles When Off */

.index__slider___1KZw0 {
  /* Slider can't shrink otherwise the dot moves outside
  the slider */
  flex-shrink: 0;
  position: relative;
  background-color: var(--color-basaltGray-40);
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.4s ease-out;
  will-change: background-color, transform;
}

.index__slider___1KZw0.index__medium___1Snr5 {
  height: 1.5rem;
  width: 2.5rem;
}

.index__slider___1KZw0.index__small___2TJ6P {
  width: 24px;
  height: 16px;
}

.index__slider___1KZw0:hover,
.index__input___1SDmg:focus + .index__slider___1KZw0,
.index__input___1SDmg:focus + .index__slider___1KZw0:hover {
  background-color: var(--color-basaltGray-50);
}

/* Slider Styles When On */

.index__checked___CV0I6 + .index__slider___1KZw0 {
  background-color: var(--color-marinaBlue-40);
}

.index__checked___CV0I6:focus + .index__slider___1KZw0,
.index__checked___CV0I6:focus + .index__slider___1KZw0:hover,
.index__checked___CV0I6 + .index__slider___1KZw0:hover {
  background-color: var(--color-marinaBlue-50);
}

/* Disabled Styles */

.index__checked___CV0I6:focus + .index__slider___1KZw0.index__disabled___3J_93,
.index__input___1SDmg:focus + .index__slider___1KZw0.index__disabled___3J_93,
.index__checked___CV0I6 + .index__disabled___3J_93,
.index__input___1SDmg + .index__disabled___3J_93 {
  opacity: 0.5;
}

.index__checked___CV0I6:focus + .index__slider___1KZw0.index__disabled___3J_93:hover, .index__input___1SDmg:focus + .index__slider___1KZw0.index__disabled___3J_93:hover, .index__checked___CV0I6 + .index__disabled___3J_93:hover, .index__input___1SDmg + .index__disabled___3J_93:hover {
  cursor: not-allowed;
}

.index__input___1SDmg:focus + .index__slider___1KZw0.index__disabled___3J_93:hover,
.index__input___1SDmg + .index__disabled___3J_93:hover {
  background-color: var(--color-basaltGray-40);
}

.index__checked___CV0I6:focus + .index__slider___1KZw0.index__disabled___3J_93:hover,
.index__checked___CV0I6 + .index__disabled___3J_93:hover {
  background-color: var(--color-marinaBlue-40);
}

/* Dot Styles When Off*/

.index__slider___1KZw0:before {
  content: '';
  height: 1.25rem;
  width: 1.25rem;
  position: absolute;
  left: 2px;
  bottom: 2px;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 9999px;
  transition: 0.4s;
}

.index__medium___1Snr5.index__slider___1KZw0:before {
  height: 1.25rem;
  width: 1.25rem;
}

.index__small___2TJ6P.index__slider___1KZw0:before {
  height: 0.75rem;
  width: 0.75rem;
}

/* Dot Styles When On */

.index__checked___CV0I6 + .index__slider___1KZw0.index__medium___1Snr5:before {
  transform: translate3d(1rem, 0, 0);
}

.index__checked___CV0I6 + .index__slider___1KZw0.index__small___2TJ6P:before {
  transform: translate3d(0.5rem, 0, 0);
}

/* White Glow Around Dot */

.index__checked___CV0I6:focus + .index__slider___1KZw0:after, .index__input___1SDmg:focus + .index__slider___1KZw0:after {
  content: '';
  position: absolute;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 9999px;
  opacity: 0.1;
  transition: 0.4s;
}

.index__checked___CV0I6:focus + .index__slider___1KZw0.index__medium___1Snr5:after, .index__input___1SDmg:focus + .index__slider___1KZw0.index__medium___1Snr5:after {
  height: 2rem;
  width: 2rem;
  left: -4px;
  bottom: -4px;
}

.index__checked___CV0I6:focus + .index__slider___1KZw0.index__small___2TJ6P:after, .index__input___1SDmg:focus + .index__slider___1KZw0.index__small___2TJ6P:after {
  height: 1.25rem;
  width: 1.25rem;
  left: -2px;
  bottom: -2px;
}

.index__checked___CV0I6:focus + .index__slider___1KZw0.index__medium___1Snr5:after {
  transform: translate3d(1rem, 0, 0);
}

.index__checked___CV0I6:focus + .index__slider___1KZw0.index__small___2TJ6P:after {
  transform: translate3d(0.5rem, 0, 0);
}

/* Label Styles */

.index__label___t5_0J {
  /* Don't highlight text when clicking */
  color: var(--color-marinaBlue-90);
  -webkit-user-select: none;
  user-select: none;
}

.index__withChildren___1FzJt {
  margin-right: 1rem;
}
