.index__error___2Dj3J {
  color: var(--color-lobsterRed-50);
}

.index__success___1eocf {
  color: var(--color-algaeGreen-60);
}

.index__warning___1ZL8l {
  color: var(--color-submarineYellow-50);
}

.index__root___ic2Mj {
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 16px;
  width: 100%;
  min-height: 56px;
  background-color: var(--color-basaltGray-20);
  border-top: 2px solid currentColor;
  pointer-events: auto;
  overflow: hidden;
  display: flex;
}

.index__root___ic2Mj.index__info___2Z8mR {
  color: var(--color-marinaBlue-50);
}

.index__icon___2FwPA {
  flex: 0 0 auto;
  margin: 0px 4px 0px 8px;
  fill: currentColor;
  width: 24px;
  height: 24px;
}

.index__icon___2FwPA.index__info___2Z8mR {
  color: var(--color-marinaBlue-90);
}

.index__textSection___2x-8P {
  flex: 1 1 auto;
  padding: 0px 8px 0px 8px;
  margin-right: 16px;
}

.index__textSection___2x-8P.index__info___2Z8mR {
  color: var(--color-marinaBlue-90);
}

.index__subtitle___7EfjE {
  color: var(--color-basaltGray-60);
}

.index__actionButton___ah47w {
  margin-right: 8px;
}

.index__topAlignmentSpacing___25gMY {
  margin-top: 8px;
}
