.index__backdrop___2WmTb {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  will-change: opacity;
  z-index: 99;
}

.index__noScroll___1b0pq {
  overflow: hidden;
}
