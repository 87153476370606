.index__arrowDown___TEMkg {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.index__arrowDown___TEMkg,
  .index__arrowDown___TEMkg:hover {
  color: var(--color-marinaBlue-70);
  fill: currentColor;
}
