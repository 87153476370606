.index__wrapper___1o_bB {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.index__input___3Jw2f {
  position: relative;
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Hide original input so that it's still available for screen readers */
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}

/* Inner and outer rings */

.index__input___3Jw2f:before,
  .index__input___3Jw2f:after {
  content: '';
  transition-duration: 0.2s;
  transition-property: background, box-shadow, border-color, transform;
  transition-timing-funtion: ease-out;
  will-change: background, box-shadow, border-color, transform;
  visibility: visible;
  position: absolute;
  border-radius: 9999px;
  height: 1.25rem;
  width: 1.25rem;
}

/* Outer circle */

.index__input___3Jw2f:before {
  border-width: 2px;
  border-style: solid;
  border-color: var(--color-marinaBlue-90);
  border-radius: 9999px;
  background: transparent;
}

/* Inner circle */

.index__input___3Jw2f:after {
  transform-origin: center;
  background-color: transparent;
  transform: scale(0.1);
}

/* Default checked styles */

.index__input___3Jw2f:checked:before {
  border-color: var(--color-marinaBlue-50);
}

.index__input___3Jw2f:checked:after {
  transform: scale(0.5);
  background-color: var(--color-marinaBlue-50);
}

.index__input___3Jw2f:hover:before {
  border-color: var(--color-marinaBlue-50);
}

.index__input___3Jw2f:hover:checked:after {
  background-color: var(--color-marinaBlue-50);
}

.index__input___3Jw2f:focus:before, .index__input___3Jw2f .index__focus___1BM3I:before {
  border-color: var(--color-marinaBlue-50);
}

.index__input___3Jw2f:focus:checked:after, .index__input___3Jw2f .index__focus___1BM3I:checked:after {
  background-color: var(--color-marinaBlue-50);
}

.index__input___3Jw2f:active:before {
  border-color: var(--color-marinaBlue-40);
}

.index__input___3Jw2f:active:checked:after {
  background-color: var(--color-marinaBlue-40);
}

.index__input___3Jw2f:active:before, .index__input___3Jw2f:focus:before, .index__input___3Jw2f .index__focus___1BM3I:before {
  /* add a box shadow and a background for the focused ring */
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 0px 4px rgba(255, 255, 255, 0.05);
  translate: scale(2);
}

.index__wrapper___1o_bB.index__disabled___39s4i {
  opacity: 0.3;
  cursor: not-allowed;
}

.index__wrapper___1o_bB.index__disabled___39s4i .index__input___3Jw2f {
  cursor: not-allowed;
}

.index__wrapper___1o_bB.index__disabled___39s4i .index__input___3Jw2f:before {
  border-color: var(--color-marinaBlue-60);
}

.index__wrapper___1o_bB.index__disabled___39s4i .index__input___3Jw2f:checked:after {
  background-color: var(--color-marinaBlue-60);
}

.index__wrapper___1o_bB.index__disabled___39s4i .index__label___1g8X6 {
  cursor: not-allowed;
  color: var(--color-marinaBlue-90);
}

.index__wrapper___1o_bB.index__error___uiGYS .index__input___3Jw2f:before {
  border-color: var(--color-lobsterRed-60);
}

.index__wrapper___1o_bB.index__error___uiGYS .index__input___3Jw2f:checked:after {
  background-color: var(--color-lobsterRed-60);
}

.index__label___1g8X6 {
  color: var(--color-marinaBlue-90);
  margin-left: 0.5rem;
}
