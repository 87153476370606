.styles__container___2xjy9 {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

@supports not (gap: 0.5rem) {
  .styles__container___2xjy9 > * + * {
    margin-top: 0.5rem;
  }
}

.styles__select___1kkHq {
  font-size: 1rem;
  line-height: 140%;
  color: var(--color-marinaBlue-90);
  outline: none;
  border: 0;
}

.styles__select___1kkHq .select__placeholder {
  color: var(--color-marinaBlue-70);
}

.styles__select___1kkHq .select__multi-value {
  border-radius: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 4px;
  padding-bottom: 4px;
  overflow: hidden;
  flex-wrap: nowrap;
  background: rgba(var(--color-marinaBlue-90-rgb), 0.1);
}

.styles__select___1kkHq .select__input-container,
  .styles__select___1kkHq .select__single-value {
  color: var(--color-marinaBlue-90);
  font-size: 1rem;
  line-height: 140%;
}

.styles__select___1kkHq .select__indicators {
  height: 2.375rem;
}

.styles__select___1kkHq .select__control {
  border-color: transparent;
  transition: border ease-in-out 0.2s;
  box-shadow: none;
  background-color: rgba(var(--color-marinaBlue-90-rgb), 0.1);
}

.styles__select___1kkHq .select__control:hover {
  border-color: var(--color-marinaBlue-60);
}

.styles__select___1kkHq .select__control.select__control--is-focused,
    .styles__select___1kkHq .select__control.select__control--is-open {
  border-color: var(--color-marinaBlue-80);
}

.styles__select___1kkHq .select__menu {
  border-radius: 0.25rem;
  background-color: var(--color-marinaBlue-20);
  color: var(--color-marinaBlue-90);
  border-color: transparent;
  overflow: hidden;
}

.styles__select___1kkHq .select__menu-list {
  padding: 0;
}

.styles__select___1kkHq .select__group {
  position: relative;
}

.styles__select___1kkHq .select__group:not(:first-child):before {
  content: '';
  top: 0;
  position: absolute;
  height: 1px;
  left: 1rem;
  right: 1rem;
  background-color: rgba(var(--color-marinaBlue-90-rgb), 0.1);
}

.styles__select___1kkHq .select__group-heading {
  font-size: 0.75rem;
  line-height: 140%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4px;
  text-transform: none;
}

.styles__select___1kkHq .select__option {
  background-color: var(--color-marinaBlue-20);
  font-size: 0.875rem;
  line-height: 140%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  transition: background ease-in-out 0.2s;
}

.styles__select___1kkHq .select__option:active {
  background-color: var(--color-marinaBlue-40);
}

.styles__select___1kkHq .select__option:hover {
  background-color: var(--color-marinaBlue-30);
}

.styles__select___1kkHq .select__menu-notice {
  font-size: 0.875rem;
  line-height: 140%;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.styles__disabled___3gNyI {
  opacity: 0.4;
}

.styles__error___1azGQ .select__control {
  border-color: var(--color-lobsterRed-60);
}
