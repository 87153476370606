.styles__wrapper___2Y8Vb {
  display: flex;
  align-items: center;
}

.styles__wrapper___2Y8Vb > button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.styles__wrapper___2Y8Vb > button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.styles__wrapper___2Y8Vb > button:not(:first-child):not(:last-child) {
  border-radius: 0;
  border-right: none;
}
