.index__wrapper___2gfB3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}

.index__inputWrapper___2yfqh {
  display: flex;
  width: 100%;
}

.index__inputWrapper___2yfqh .index__input___3VLOH.index__withSuffix___3xv2U {
  margin-right: 1rem;
}

.index__label___g9amj {
  font-size: 0.875rem;
  line-height: 140%;
  color: var(--color-marinaBlue-90);
  margin-bottom: 0.5rem;
}

.index__helperText___2Cr3u {
  font-size: 0.875rem;
  line-height: 140%;
  color: var(--color-basaltGray-60);
  margin-top: 0.5rem;
  width: 100%;
}

.index__input___3VLOH,
.index__prefix___cx48s {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  font-size: 1rem;
  line-height: 140%;
  color: var(--color-marinaBlue-90);
  border-width: 1px;
  border-style: solid;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-color: transparent;
  border-radius: 0.25rem;
  background-color: rgba(var(--color-marinaBlue-90-rgb), 0.1);
}

.index__inputWrapper___2yfqh:hover .index__input___3VLOH,
  .index__inputWrapper___2yfqh:hover .index__prefix___cx48s {
  border-color: var(--color-marinaBlue-60);
}

.index__inputWrapper___2yfqh .index__input___3VLOH::placeholder {
  color: var(--color-marinaBlue-70);
}

.index__inputWrapper___2yfqh .index__input___3VLOH:focus,
  .index__inputWrapper___2yfqh .index__input___3VLOH:focus + .index__prefix___cx48s {
  border-color: var(--color-marinaBlue-80);
}

.index__inputWrapper___2yfqh .index__input___3VLOH:disabled,
  .index__inputWrapper___2yfqh .index__input___3VLOH:disabled + .index__prefix___cx48s {
  border-color: transparent;
  cursor: not-allowed;
  color: var(--color-basaltGray-50);
  opacity: 0.4;
}

.index__inputWrapper___2yfqh .index__input___3VLOH[readonly],
  .index__inputWrapper___2yfqh .index__input___3VLOH[readonly] + .index__prefix___cx48s {
  /* Readonly inputs can be copied but not edited.
    Use pointer instead of text cursor */
  cursor: pointer;
}

.index__withPrefix___1Q6ZZ {
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.index__prefix___cx48s {
  order: -9999;
  flex-shrink: 0;
  width: auto;
  padding-left: 1rem;
  padding-right: 0;
  font-family: Akkurat Mono, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --text-opacity: 0.4;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
}

.index__prefix___cx48s:hover {
  cursor: default;
}

.index__prefix___cx48s:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.index__inputWithPrefixPadding___3ZPx9 {
  padding-left: 0.5rem;
}

.index__iconPrefix___RkKAT {
  padding-left: 0.5rem;
}

.index__iconPrefix___RkKAT svg {
  width: 1.25rem;
  fill: currentColor;
}

.index__textarea___1_cnD {
  /* IE puts a vertical scrollbar by default,
    this removes it by default but puts it back when needed*/
  /* Since Volkan is so set on supporting IE 😉 */
  overflow: auto;
  resize: none;
}

.index__error___1_nap .index__input___3VLOH,
  .index__error___1_nap .index__prefix___cx48s {
  border-color: var(--color-lobsterRed-60);
}

.index__error___1_nap .index__helperText___2Cr3u {
  color: var(--color-lobsterRed-60);
}
