.index__selectWrapper___2O-qy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* https://github.com/JedWatson/react-input-autosize#csp-and-the-ie-clear-indicator */

.index__select___3b7gw input::-ms-clear {
  display: none;
}

.index__select___3b7gw {
  width: 100%;
}

/* Fill the parent container */

.index__select___3b7gw .Select-multi-value-wrapper {
  display: inline-flex;
  flex-wrap: wrap;
}

/* Select box */

.index__select___3b7gw .Select-control {
  border-color: transparent;
  color: var(--color-marinaBlue-80);
  font-size: 1rem;
  line-height: 140%;
  background: rgba(var(--color-marinaBlue-90-rgb), 0.1) !important;
}

.index__select___3b7gw .Select-control:hover {
  border-color: var(--color-marinaBlue-60);
}

.index__select___3b7gw .Select-control:hover {
  border-color: var(--color-marinaBlue-60);
}

.index__select___3b7gw .Select-control:focus,
    .index__select___3b7gw .Select-control:active {
  border-color: var(--color-marinaBlue-80);
}

/* Same as focus and active states above */

.index__select___3b7gw.is-focused > .index__Select-control___2a9o6,
  .index__select___3b7gw.is-open > .index__Select-control___2a9o6 {
  border-color: var(--color-marinaBlue-80);
}

/* Placeholder */

.index__select___3b7gw .Select-placeholder {
  color: var(--color-marinaBlue-70);
}

/* Selected values */

.index__select___3b7gw .Select-value,
  .index__select___3b7gw .Select-value-label {
  border-color: transparent !important;
  color: var(--color-marinaBlue-90) !important;
}

/* Select input when focused
  Required to remove white background when select is not searchable */

.index__select___3b7gw .Select-input:focus {
  background-color: transparent;
}

/* Input */

.index__select___3b7gw .Select-input > input {
  color: var(--color-marinaBlue-80);
}

/* Options Menu */

.index__select___3b7gw .Select-menu-outer {
  margin-top: 4px;
  border-radius: 0.25rem;
  padding-top: 4px;
  padding-bottom: 4px;
}

.index__select___3b7gw .Select-menu-outer,
  .index__select___3b7gw .Select-menu,
  .index__select___3b7gw .Select-option {
  background-color: var(--color-marinaBlue-20);
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  border-color: transparent;
}

/* Options  */

.index__select___3b7gw .Select-option.is-focused {
  background-color: var(--color-marinaBlue-30);
}

.index__select___3b7gw .Select-option.is-selected {
  font-weight: 700;
}

/* Disabled styles */

.index__select___3b7gw.is-disabled .Select-control {
  cursor: not-allowed;
  border-color: transparent;
  opacity: 0.4;
}

.index__select___3b7gw.is-open .Select-control {
  border-color: var(--color-marinaBlue-80) !important;
  border-radius: 0.25rem !important;
}

.index__label___qjSe3 {
  font-size: 0.875rem;
  line-height: 140%;
  color: var(--color-marinaBlue-90);
  margin-bottom: 0.5rem;
}

.index__helperText___1ONqQ {
  font-size: 0.875rem;
  line-height: 140%;
  color: var(--color-marinaBlue-90);
  margin-top: 0.5rem;
}

.index__arrow___esUJz {
  height: 1rem;
  width: 1rem;
}

/* Error styles */

.index__error___1qksD .Select-control {
  border-color: var(--color-lobsterRed-60);
}

.index__error___1qksD .index__input___1IZMY {
  border-color: var(--color-lobsterRed-60);
}

.index__error___1qksD .index__helperText___1ONqQ {
  color: var(--color-lobsterRed-60);
}
