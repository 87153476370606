.index__clearIndicator___3KrgW {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.index__clearIndicator___3KrgW,
  .index__clearIndicator___3KrgW:hover {
  color: var(--color-marinaBlue-70);
  fill: currentColor;
}

.index__clearIndicatorWrapper___3b4_g {
  display: flex;
  align-items: center;
}
