.index__heading___PSyOa {
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 140%;
}

.index__heading___PSyOa.index__huge___26N85 {
  font-size: 2rem;
}

.index__heading___PSyOa.index__large___U12-n {
  font-size: 1.75rem;
}

.index__heading___PSyOa.index__medium___28PCQ {
  font-size: 1.5rem;
}

.index__heading___PSyOa.index__small___2RhUu {
  font-size: 1.125rem;
}

.index__label___ltVaS {
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 140%;
  letter-spacing: 0.04rem;
}

.index__label___ltVaS.index__medium___28PCQ {
  font-size: 0.875rem;
}

.index__label___ltVaS.index__small___2RhUu {
  font-size: 0.75rem;
}

.index__label___ltVaS.index__tiny___2kCLq {
  font-size: 0.625rem;
}

.index__body___1BL32 {
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 140%;
}

.index__body___1BL32.index__large___U12-n {
  font-size: 1.125rem;
}

.index__body___1BL32.index__medium___28PCQ {
  font-size: 1rem;
}

.index__body___1BL32.index__small___2RhUu {
  font-size: 0.875rem;
}

.index__body___1BL32.index__tiny___2kCLq {
  font-size: 0.75rem;
}

.index__metric___25cd8 {
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 140%;
}

.index__metric___25cd8.index__large___U12-n {
  font-size: 2.5rem;
}

.index__metric___25cd8.index__medium___28PCQ {
  font-size: 2rem;
}

.index__metric___25cd8.index__small___2RhUu {
  font-size: 1.5rem;
}

.index__metric___25cd8.index__tiny___2kCLq {
  font-size: 1rem;
}

.index__mono___24UvB {
  font-family: Akkurat Mono, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 140%;
}

.index__mono___24UvB.index__medium___28PCQ {
  font-size: 1rem;
}

.index__mono___24UvB.index__small___2RhUu {
  font-size: 0.875rem;
}

.index__mono___24UvB.index__tiny___2kCLq {
  font-size: 0.75rem;
}

.index__link___3Hymu {
  color: var(--color-marinaBlue-50);
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.index__link___3Hymu.index__small___2RhUu {
  font-size: 0.875rem;
}

.index__link___3Hymu.index__medium___28PCQ {
  font-size: 1rem;
}

.index__link___3Hymu {
  cursor: pointer;
  transition: color 0.3s;
}

.index__link___3Hymu:hover,
  .index__link___3Hymu:focus {
  text-decoration: underline;
  color: var(--color-marinaBlue-80);
}

.index__link___3Hymu:active {
  text-decoration: underline;
  color: var(--color-marinaBlue-90);
}

.index__link___3Hymu:disabled {
  cursor: default;
  text-decoration: none;
  color: var(--color-marinaBlue-70);
  --text-opacity: 0.3;
}

/* Remove button styles */

.index__link___3Hymu,
.index__link___3Hymu:hover,
.index__link___3Hymu:focus,
.index__link___3Hymu:active,
.index__link___3Hymu:disabled {
  padding: 0;
  background-color: transparent;
  border-style: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
