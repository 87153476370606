.index__container___30Bdt {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  grid-gap: 8px;
  gap: 8px;
}

.index__wrapper___1UsX6 {
  display: flex;
  justify-content: space-between;
  border: 1px solid transparent;
  height: 40px;
  border-radius: 4px;
  background-color: rgba(var(--color-marinaBlue-90-rgb), 0.1);
  transition: border 0.2s ease-in-out;
  width: 100%;
  color: var(--color-basaltGray-60);
  padding: 8px;
}

.index__wrapper___1UsX6:focus-within {
  border: 1px solid var(--color-marinaBlue-50);
  border-radius: 4px;
  color: var(--color-marinaBlue-90);
}

.index__wrapper___1UsX6:focus-within svg {
  fill: var(--color-marinaBlue-90);
}

.index__wrapper___1UsX6:hover {
  border: 1px solid var(--color-marinaBlue-80);
}

input {
  width: 100%;
  background-color: transparent;
  outline: none;
  padding: 0 8px;
  color: var(--color-marinaBlue-90);
  align-self: center;
  border: 1px solid transparent;
}

input::placeholder {
  color: var(--color-basaltGray-60);
}

input[type='password'] {
  padding-right: 28px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.index__prefix___1ryyU,
.index__suffix___3QuJR {
  display: flex;
  justify-content: center;
  cursor: text;
  align-items: center;
}

.index__label___21b1Z {
  color: var(--color-marinaBlue-90);
}

.index__helperText___2QpEX {
  color: var(--color-basaltGray-60);
}

.index__error___Lb9JP {
  color: var(--color-lobsterRed-60);
}

.index__errorBorder___1oAs7 {
  border: 1px solid var(--color-lobsterRed-60);
}

.index__disabled___2FDeS {
  pointer-events: none;
  border: transparent;
  opacity: 0.5;
}

.index__icon___wrdK2 {
  fill: currentColor;
  width: 24px;
  height: 24px;
}
