.index__avatar___ijOzx {
  color: var(--color-marinaBlue-10);
  background-color: var(--color-marinaBlue-90);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
  box-shadow: 0px 0px 0px 2px var(--color-marinaBlue-10);
}

.index__bordered___1PTAQ {
  box-shadow: 0px 0px 0px 2px var(--color-marinaBlue-10),
    0px 0px 0px 4px var(--color-marinaBlue-50);
}

.index__bordered___1PTAQ:focus {
  box-shadow: 0px 0px 0px 2px var(--color-marinaBlue-10),
    0px 0px 0px 4px var(--color-marinaBlue-80);
}

.index__interactive___30oEx {
  cursor: pointer;
  outline: none;
}

.index__avatar___ijOzx.index__tiny___1gSpI {
  width: 24px;
  height: 24px;
}

.index__avatar___ijOzx.index__small___IPCpD {
  width: 40px;
  height: 40px;
}

.index__avatar___ijOzx.index__medium___aJ2rV {
  width: 48px;
  height: 48px;
}

.index__avatar___ijOzx.index__large___1xoae {
  width: 56px;
  height: 56px;
}

.index__avatarGroupWrapper___3IrLw {
  display: flex;
  padding-left: 8px;
}

.index__avatarGroupWrapper___3IrLw .index__avatar___ijOzx {
  margin-left: -8px;
}

.index__avatarGroupWrapper___3IrLw.index__tiny___1gSpI .index__avatar___ijOzx {
  width: 24px;
  height: 24px;
}

.index__avatarGroupWrapper___3IrLw.index__small___IPCpD .index__avatar___ijOzx {
  width: 40px;
  height: 40px;
}

.index__avatarGroupWrapper___3IrLw.index__medium___aJ2rV .index__avatar___ijOzx {
  width: 48px;
  height: 48px;
}

.index__avatarGroupWrapper___3IrLw.index__large___1xoae .index__avatar___ijOzx {
  width: 56px;
  height: 56px;
}
