
      @import '@anchorage/config/scss/variables.scss';
      @import '@anchorage/config/scss/mixins.scss';
      @import '@anchorage/config/scss/fonts.scss';
      
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import '@anchorage/common/dist/components/index.css';
  @import '@latitude/theme/css/overrides/common.css';
  @import '@latitude/theme/css/overrides/normalize.css';
  @import '@latitude/theme/css/tokens/anchorage-dark.css';
  /* @import '@latitude/theme/css/tokens/anchorage-light.css'; */
  @import '@latitude/theme/css/tokens/porto-dark.css';
  /* @import '@latitude/theme/css/tokens/porto-light.css'; */
}
