.styles__titleContainer___3axJW {
  display: flex;
  grid-gap: 4px;
  grid-gap: 4px;
  gap: 4px;
  flex-direction: column;
}

.styles__description___gNwb4 {
  color: var(--color-basaltGray-60);
}

.styles__headerContainer___2MqJ0 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.styles__progressContainer___AtfPn {
  display: flex;
  grid-gap: 8px;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}

.styles__modalHeader___i6khs {
  padding: 16px;
  border-bottom: 1px solid rgba(var(--color-basaltGray-90-rgb), 0.2);
}


