.index__button___1mmZb {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  outline: 2px solid transparent;
  outline-offset: 2px;
  cursor: pointer;
}

.index__button___1mmZb:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.index__button___1mmZb:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.index__button___1mmZb {
  line-height: inherit;
}

/* For when the button has an icon and children */

.index__hasIcon___3NxZa {
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

/* For when the button only has an icon (no children) */

.index__iconOnly___2ktua {
  padding: 0.5rem;
}

.index__icon___1u7x8 {
  height: 1.25rem;
  width: 1.25rem;
  fill: currentColor;
}

.index__margin___1yTJl {
  margin-right: 0.5rem;
}

.index__button-primary___f7Rb_ {
  border-color: transparent;
  background-color: var(--color-marinaBlue-50);
  color: var(--button-text-primary);
}

.index__button-primary___f7Rb_:hover {
  background-color: var(--color-marinaBlue-30);
  color: var(--button-text-primary-hover);
}

.index__button-primary___f7Rb_:focus {
  border-color: var(--color-marinaBlue-70);
}

.index__button-primary___f7Rb_:active,
  .index__button-primary___f7Rb_.index__active___3KSXf {
  background-color: var(--color-marinaBlue-20);
  color: var(--button-text-primary-hover);
}

.index__button-primary___f7Rb_:disabled {
  background-color: var(--color-marinaBlue-40);
}

.index__button-secondary___eUE_5 {
  border-color: var(--color-marinaBlue-50);
  background-color: transparent;
  color: var(--color-marinaBlue-50);
}

.index__button-secondary___eUE_5:hover {
  background-color: var(--color-marinaBlue-20);
}

.index__button-secondary___eUE_5:focus {
  background-color: var(--color-marinaBlue-20);
  border-color: var(--color-marinaBlue-80);
}

.index__button-secondary___eUE_5:active,
  .index__button-secondary___eUE_5.index__active___3KSXf {
  border-color: var(--color-marinaBlue-60);
  background-color: var(--color-marinaBlue-30);
}

.index__button-secondary___eUE_5:disabled {
  border-color: var(--color-marinaBlue-60);
}

.index__button-secondary___eUE_5:disabled:hover {
  background-color: transparent;
}

.index__button-tertiary___2CYKG {
  border-color: transparent;
  background-color: transparent;
  color: var(--color-marinaBlue-90);
}

.index__button-tertiary___2CYKG:hover {
  background-color: var(--color-marinaBlue-20);
}

.index__button-tertiary___2CYKG:focus {
  background-color: var(--color-marinaBlue-20);
  border-color: var(--color-marinaBlue-80);
}

.index__button-tertiary___2CYKG:active,
  .index__button-tertiary___2CYKG.index__active___3KSXf {
  background-color: var(--color-marinaBlue-30);
  border-color: transparent;
}

.index__button-tertiary___2CYKG:disabled {
  border-color: transparent;
}

.index__button-tertiary___2CYKG:disabled:hover {
  background-color: transparent;
}

.index__button-destructive___2os70 {
  border-color: var(--color-lobsterRed-60);
  background-color: transparent;
  color: var(--color-lobsterRed-60);
}

.index__button-destructive___2os70:hover {
  background: rgba(var(--color-lobsterRed-60-rgb), 0.2);
}

.index__button-destructive___2os70:focus {
  background: rgba(var(--color-lobsterRed-60-rgb), 0.2);
  border-color: var(--color-lobsterRed-70);
}

.index__button-destructive___2os70:active,
  .index__button-destructive___2os70.index__active___3KSXf {
  background: rgba(var(--color-lobsterRed-50-rgb), 0.3);
  border-color: var(--color-lobsterRed-60);
}

.index__button-destructive___2os70:disabled {
  border-color: var(--color-lobsterRed-60);
}

.index__button-destructive___2os70:disabled:hover {
  background-color: transparent;
}

.index__button-success___2CZlj {
  border-color: var(--color-algaeGreen-60);
  background-color: transparent;
  color: var(--color-algaeGreen-60);
}

.index__button-success___2CZlj:hover {
  background: rgba(var(--color-algaeGreen-60-rgb), 0.2);
}

.index__button-success___2CZlj:focus {
  border-color: var(--color-algaeGreen-90);
}

.index__button-success___2CZlj:active,
  .index__button-success___2CZlj.index__active___3KSXf {
  background: rgba(var(--color-algaeGreen-60-rgb), 0.3);
}

.index__button-success___2CZlj:disabled {
  border-color: var(--color-algaeGreen-60);
}

.index__button-success___2CZlj:disabled:hover {
  background-color: transparent;
}

.index__destructive___EZfIs {
  color: var(--color-lobsterRed-60);
}

.index__success___1U9Fg {
  color: var(--color-algaeGreen-60);
}

.index__loadingWrapper___vfxYd {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-property: opacity;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  --text-opacity: 0;
  opacity: 0;
}

.index__loading___NdMRM {
  --text-opacity: 0;
}

.index__loading___NdMRM:disabled {
  opacity: 1;
}

.index__loading___NdMRM > * {
  opacity: 0;
}

.index__loading___NdMRM .index__loadingWrapper___vfxYd {
  --text-opacity: 1;
  opacity: 1;
}
