.index__tabs___2b5U_ {
  position: relative;
  display: flex;
}

.index__bar___BQtk3 {
  border-radius: 4px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: var(--color-marinaBlue-90);
  border-radius: 4px;
  transition: transform 0.1s ease-in-out;
  will-change: transform;
}

.index__barFull___2MPNu {
  background-color: var(--color-basaltGray-60);
}

.index__tab___1xB_7 {
  transition: color 0.1s ease-in-out;
  color: var(--color-basaltGray-60);
  margin-bottom: 4px;
  cursor: pointer;
}

.index__tab___1xB_7:hover {
  color: var(--color-basaltGray-50);
}

.index__tab-medium___1TX2f {
  flex: 1 1 0%;
}

.index__tab-small___3Y7j2 {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1.5rem;
  display: flex;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}

.index__tab-small___3Y7j2:not(:first-child) {
  margin-left: 1.5rem;
}

.index__tab-small___3Y7j2:not(:last-of-type)::before {
  content: '';
  position: absolute;
  top: 0.5rem;
  bottom: 0.5rem;
  right: -1.5rem;
  width: 1px;
  background: var(--color-marinaBlue-30);
  border-radius: 2px;
}

.index__tab-small___3Y7j2::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 4px;
  background: var(--color-marinaBlue-90);
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.index__tab-small___3Y7j2.index__selected___3IGHu::after {
  opacity: 1;
}

.index__selected___3IGHu {
  color: var(--color-marinaBlue-90);
}

.index__selected___3IGHu:hover {
  color: var(--color-marinaBlue-90);
}
