.index__stepRoot___1vYzJ {
  --icon-size: 24px;
  display: flex;
  position: relative;
}

.index__stepRoot___1vYzJ .index__stepRoot___1vYzJ:first-child {
  margin-top: 8px;
}

.index__stepRoot___1vYzJ .index__stepRoot___1vYzJ .index__line___1Yelf {
  display: none;
}

.index__stepRoot___1vYzJ .index__stepRoot___1vYzJ .index__stepIcon___2kY-M {
  width: 16px;
  height: 16px;
}

.index__icon___1ckU- {
  fill: currentColor;
  margin-right: 12px;
}

.index__stepIcon___2kY-M {
  width: var(--icon-size);
  height: var(--icon-size);
  flex-shrink: 0;
}

.index__opacity___2bMni {
  opacity: 0.3;
}

.index__complete___1aReh {
  color: var(--color-seafoamTeal-60);
}

.index__error___23Q1G {
  color: var(--color-lobsterRed-60);
}

.index__incomplete___3rj53 {
  color: var(--color-basaltGray-60);
  opacity: 0.3;
}

.index__warning___3BEES {
  color: var(--color-submarineYellow-80);
}

.index__active___3SsK_ {
  color: var(--color-marinaBlue-90);
}

.index__root___GJtFz .index__stepRoot___1vYzJ:last-of-type .index__line___1Yelf {
  display: none;
}

.index__root___GJtFz > .index__stepRoot___1vYzJ:last-child .index__textSection___2vbaz {
  padding-bottom: 0px;
}

.index__line___1Yelf {
  --size: calc(var(--icon-size) - 4px);
  --thickness: 2px;
  height: calc(100% - var(--size));
  left: calc((var(--size) / 2) + (var(--thickness) / 2));
  top: calc(var(--size) + 2px);
  position: absolute;
  border-left: solid var(--thickness) currentColor;
}

.index__title___3zi9G {
  color: var(--color-marinaBlue-90);
}

.index__description___3Oo5g {
  color: var(--color-basaltGray-60);
}

.index__textSection___2vbaz {
  flex: 1 1 auto;
}

.index__textSection___2vbaz {
  padding-bottom: 16px;
}

.index__textSection___2vbaz .index__textSection___2vbaz {
  padding-bottom: 8px;
}
