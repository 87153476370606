
      @import '@anchorage/config/scss/variables.scss';
      @import '@anchorage/config/scss/mixins.scss';
      @import '@anchorage/config/scss/fonts.scss';
      
@import './tailwind.css';

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  color: var(--color-marinaBlue-90);

  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--colorRefUiBackgroundBase);
}

a {
  text-decoration: inherit;
  color: var(--color-marinaBlue-50);
}

.markdown > * {
  all: revert;
}

table {
  /* Necessary to remove TableV2 column header spacing */
  border-collapse: collapse;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --font-family: var(--font-inter), 'Akkurat Pro', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  line-height: 1.5;
  box-sizing: border-box;
  --long-animation-duration: 1.4s;

  /* Dropdown */
  --dropdown-menu-divider-color: rgba($color-marinaBlue-90-rgb, 0.1);
  --dropdown-menu-color: var(--color-white);

  /* Spacing */
  --space-xxs: 4px;
  --space-xs: 8px;
  --space-sm: 12px;
  --space-md: 16px;
  --space-lg: 20px;
  --space-xl: 24px;
  --space-xxl: 32px;
  --space-xxxl: 40px;

  /* Spacing Usage */
  --table-cell-margin-top: var(--space-xxs);
  --app-horizontal-margin: 40px;
  --app-vertical-margin: 24px;
  --header-height: 72px;
  --sidenav-width: 280px;
  --legacy-max-content-width: 1224px;
  --max-content-width: 1440px;
  --content-horizontal-gutter: 40px;
  --content-vertical-gutter: 50px;

  --drawer-horizontal-gutter: 64px;
  --drawer-vertical-gutter: 64px;

  --control-padding-horizontal: 12px;

  --drawer-section-title-spacing: 8px;
  --drawer-section-spacing: 30px;
  --label-spacing: var(--space-xxs);
  --form-item-spacing: var(--space-xl);
  --corner-size: var(--space-xxs);

  --input-width: 340px;
  --input-large-width: 640px;

  /* Fonts */
  --font-size-0: 11px;
  --font-size-1: 12px;
  --font-size-2: 14px;
  --font-size-3: 18px;
  --font-size-4: 24px;
  --font-size-5: 32px;

  /* Icons */
  --name-logo-height: 64px;
  --icon-size: var(--space-lg);
  --icon-text-spacing: var(--space-sm);

  /* Animations */
  --antd-animation-duration: 0.2s;
  --quick-animation-duration: 0.3s;
  --fade-animation-duration: 0.8s;
  --long-animation-duration: 1.4s;

  /* Shadows */
  --card-drop-shadow: 0px 2px 4px rgba(var(--color-black), 0.05);
  --vault-drop-shadow: 0px 4px 8px rgba(var(--color-black), 0.2);

  /* AntD */
  --modal-padding: 40px;
  --close-offset: calc(2 * var(--modal-padding));

  --close-x-size: 24px;
  --close-x-offset: 24px;

  /* Login Page */
  /* Matches QR_CODE_SIZE from 'LoginPage/constants' */
  --qr-code-size: 240px;
  --desktop-breakpoint: 1024px;
  --logo-padding: var(--app-vertical-margin);

  --instructions-width: 50%;

  --header-height: calc(var(--name-logo-height) + 2 * var(--logo-padding));
  --instructions-height: calc(100vh - var(--header-height));

  /* QRCode */
  --qr-code-circle-size: 200px;
  --qr-code-circle-offset: calc(
    (var(--qr-code-size) - --qr-code-circle-size) / 2
  );
  --frame-color: var(--color-marinaBlue-30);
  --frame-size: 22px;
  --frame-padding: 0px;
  --frame-position: calc((var(--frame-size) + var(--frame-padding)) * -1);
}

.App {
  text-align: center;
  display: grid;
  grid-template-columns: 4rem auto;
  color: var(--color-white);
  text-align: left;
}

.App-main {
  padding: 24px;
}

*,
::before,
::after {
  box-sizing: inherit;
}
