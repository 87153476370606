.index__backdrop___25n2g {
  background: rgba(0,0,0, 0.6);
  --bg-opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99;
}

.index__wrapper___18WVV {
  background-color: var(--color-marinaBlue-10);
  color: var(--color-marinaBlue-90);
  border-radius: 0.25rem;
  margin-right: 0;
  overflow: auto;
  position: fixed;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  width: 75vw;
  max-width: 576px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.index__wrapper___18WVV:focus {
  outline: none;
}

.index__header___1rmtg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.index__title___rzOm9 {
  font-size: 1.5rem;
  line-height: 140%;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 1.5rem;
}

.index__closeButton___3PXBI {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.index__closeIcon___30TqM {
  fill: currentColor;
  width: 1.5rem;
  height: 1.5rem;
}

.index__content___3mZFB {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  flex-grow: 1;
  overflow: auto;
  min-height: 60px;
  max-height: 40vh;
}

.index__footer___1P9ch {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.index__submitButton___3Dgz1 {
  margin-left: 1rem;
}

.index__errorMessage___15-VE {
  width: 100%;
  color: var(--color-lobsterRed-60);
  display: flex;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 4px;
  font-size: 1rem;
  line-height: 140%;
  line-height: 140%;
  background: rgba(var(--color-marinaBlue-10-rgb), 0.5);
}

.index__errorMessageText___1Abcv {
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.index__errorIcon___3SXS9 {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
}

/* Apply very basic animation to fade in the modal  */

.ReactModal__Overlay {
  opacity: 0;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
