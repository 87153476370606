.index__table___2u3ce {
  width: 100%;
  font-size: 0.875rem;
  line-height: 140%;
  color: var(--color-marinaBlue-90);
}

/* Table Head */

.index__tableHead___RwMZZ {
  text-align: left;
  background-color: var(--color-marinaBlue-20);
  line-height: 1;
}

.index__tableHeadCell___3hUXS {
  font-weight: 400;
}

.index__tableHeadTitleCenter___2-lo4 {
  text-align: center;
}

.index__tableHeadTitleRight___3r_hK {
  text-align: right;
}

.index__tableHeadTitle___SWIV3 {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;
  color: var(--color-marinaBlue-80);
}

.index__sort___3xwTG {
  cursor: pointer;
}

.index__arrow___2x4wS {
  margin-left: 4px;
  display: inline;
  margin-bottom: 2px; /* for weird alignment issue */
  transition: transform 0.2s ease-in-out;
}

.index__rotate180___3XdQn {
  transform: rotate(-180deg);
}

/* Table Body */

.index__tableBodyText___23iza {
  padding: 1rem;
  text-align: left;
  color: var(--color-marinaBlue-90);
  vertical-align: top;
}

.index__tableBodyClickable___3B1P7:hover {
  cursor: pointer;
}

.index__tableBodyClickable___3B1P7:hover,
  .index__tableBodyClickable___3B1P7:focus {
  background-color: rgba(var(--color-marinaBlue-90-rgb), 0.05);
}

.index__tableBodyTextFullWidth___3u1cU {
  width: 100%;
}

.index__tableBodyTextCenter___i-eiH {
  text-align: center;
}

.index__tableBodyTextRight___G3n0Z {
  text-align: right;
}

.index__borderBottom___2dLkU {
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: var(--color-marinaBlue-20);
}

.index__centerText___LL3BS {
  text-align: center;
}

/* Pagination */

.index__paginationButtonsWrapper___1CHhG {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

@supports not (gap: 0.5rem) {
  .index__paginationButtonsWrapper___1CHhG > * + * {
    margin-left: 0.5rem;
  }
}

.index__paginationButton___pWRbE {
  display: inline-flex;
}

.index__paginationButton___pWRbE svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--color-marinaBlue-80);
}

.index__loader___3UKv9 {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  margin-left: 1rem;
}

.index__expandable___crAuq {
  height: 40px;
  margin-left: 16px;
}

.index__expandableIcon___IO8FL {
  fill: var(--color-marinaBlue-90);
  width: 20px;
  height: 20px;
}
