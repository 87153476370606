.index__sidebarStep___3R2lA {
  display: flex;
  grid-gap: 8px;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  padding: 12px 8px 12px 16px;
  align-items: flex-start;
  background: rgba(var(--color-basaltGray-10-rgb), 0.6);
}

.index__sidebarStep___3R2lA:active,
  .index__sidebarStep___3R2lA:focus,
  .index__sidebarStep___3R2lA:hover {
  border: 1px solid transparent;
  background: rgba(var(--color-basaltGray-10-rgb), 0.6);
}

.index__icon___24ZDV {
  width: 24px;
  height: 24px;
}

.index__defaultIcon___Kk_Zn {
  fill: var(--color-marinaBlue-90);
}

/* TODO jenny: replace colors with updated variables to test colors after design
changes are merged
*/

.index__current___2anWx {
  border-radius: 8px;
  background-color: #1d1d1f;
}

.index__current___2anWx:active,
  .index__current___2anWx:focus,
  .index__current___2anWx:hover {
  background: #1d1d1f;
  border: 1px solid transparent;
}

.index__incompleteIcon___4jnAT {
  fill: var(--color-basaltGray-60);
}

.index__incompleteText___3NQcL {
  color: var(--color-basaltGray-60);
}

.index__unclickableStep___1KGKy {
  pointer-events: none;
}
