.index__container___e2XD7{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.index__loader___3-vpl {
  width: 1rem;
  height: 1rem;
  padding: 0;
}