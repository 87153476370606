.index__container___2BDOs {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  line-height: 140%;
  line-height: 1.25;
  color: var(--color-marinaBlue-90);
  background: var(--color-basaltGray-10);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  font-family: 'Inter';
}

.index__container___2BDOs.index__disabled___PF5v9 {
  position: relative; /* To be able to apply the overlay when disabled */
}

.index__overlay___2zwCB {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background: rgba(0, 21, 43, 0.4);
}

@media only screen and (min-width: 1024px) {
  .index__container___2BDOs {
    padding: 1.5rem;
  }
}

.index__header___3c6ct {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.index__text___MCZmv {
  font-size: 1.125rem;
  line-height: 140%;
  line-height: 1.625;
  white-space: nowrap;
  color: var(--color-marinaBlue-90);
}

.index__textSeparator___3kijR {
  margin-left: 4px;
  margin-right: 4px;
}

.index__action___3eNKX {
  margin-left: 0.5rem;
}

.index__footer___1Yel7 {
  display: flex;
  align-items: center;
  min-height: 1rem;
}

/* Empty footer will still be this height */

.index__footer___1Yel7 > .index__action___3eNKX {
  /* move action to right regardless if there is another child */
  margin-left: auto;
}

/* Apply bottom margin to children if present */

.index__footer___1Yel7 > * {
  margin-bottom: 1rem;
}
