.index__table___15Vs9 {
  width: 100%;
  font-size: 0.875rem;
  line-height: 140%;
  color: var(--color-marinaBlue-90);
}

/* Table Head */

.index__tableHead___z1EXP {
  text-align: left;
  background-color: var(--color-marinaBlue-20);
  line-height: 1;
}

.index__tableHead___z1EXP th {
  font-weight: 400;
}

.index__tableHeadTitleCenter___3DIHF {
  text-align: center;
}

.index__tableHeadTitleRight___3vBNj {
  text-align: right;
}

.index__tableHeadTitle___1tWPx {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;
  color: var(--color-marinaBlue-90);
}

.index__sort___2grC9 {
  cursor: pointer;
}

.index__arrow___2nl5q {
  margin-left: 4px;
  display: inline;
  margin-bottom: 2px; /* for weird alignment issue */
  transition: transform 0.2s ease-in-out;
}

.index__rotate180___2pLGe {
  transform: rotate(-180deg);
}

/* Table Body */

.index__tableBodyText___2qjQC {
  padding: 1rem;
  text-align: left;
  color: var(--color-marinaBlue-90);
  vertical-align: top;
}

.index__tableBodyClickable___2_Xql:hover {
  cursor: pointer;
}

.index__tableBodyClickable___2_Xql:hover,
  .index__tableBodyClickable___2_Xql:focus {
  background-color: rgba(var(--color-marinaBlue-90-rgb), 0.05);
}

.index__tableBodyTextFullWidth___3wRhu {
  width: 100%;
}

.index__tableBodyTextCenter___4WMfC {
  text-align: center;
}

.index__tableBodyTextRight___q00Dv {
  text-align: right;
}

.index__borderBottom___3UrqO {
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: var(--color-marinaBlue-20);
}

/* Pagination */

.index__paginationWrapper___6cF0x {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.index__button___192ye {
  border-width: 0;
  margin: 0;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-marinaBlue-70);
  background-color: transparent;
}

.index__button___192ye:hover,
  .index__button___192ye:focus {
  cursor: pointer;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: var(--color-seafoamTeal-80);
}

.index__button___192ye:active {
  color: var(--color-seafoamTeal-90);
}

.index__button___192ye:disabled {
  cursor: not-allowed;
  color: var(--color-basaltGray-60);
}

/* NoData */

.index__noData___25YzO {
  width: 100%;
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 140%;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

/* Loader */

.index__loader___3W_JS {
  width: 100%;
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Don't allow pointer events on this element since it is transparent*/
  pointer-events: none;
}
