.FileUploadBase__label___3eV1x {
  /* Padding was collapsing without display block */
  display: inline-block;
  padding-bottom: 0.5rem;
  color: var(--color-marinaBlue-90);
  font-size: 0.875rem;
  line-height: 140%;
}

.FileUploadBase__label___3eV1x:hover {
  cursor: pointer;
}

.FileUploadBase__dropbox___1NwQq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
  color: var(--color-marinaBlue-60);
  border-width: 1px;
  border-style: dashed;
  border-color: var(--color-marinaBlue-40);
  border-radius: 0.25rem;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: 300ms;
  will-change: background-color;
  background-color: transparent;
}

.FileUploadBase__dropbox___1NwQq.FileUploadBase__active___1932z,
  .FileUploadBase__dropbox___1NwQq:active,
  .FileUploadBase__dropbox___1NwQq:focus {
  border-color: var(--color-marinaBlue-80);
  outline: 2px solid transparent;
  outline-offset: 2px;
  background-color: rgba(var(--color-marinaBlue-90-rgb), 0.05);
}

.FileUploadBase__label___3eV1x:hover + .FileUploadBase__dropbox___1NwQq,
.FileUploadBase__dropbox___1NwQq:hover {
  cursor: pointer;
  background-color: rgba(var(--color-marinaBlue-90-rgb), 0.05);
}

.FileUploadBase__icon___2DMTS {
  width: 2rem;
  margin-bottom: 0.75rem;
  color: var(--color-marinaBlue-70);
  fill: currentColor;
}

.FileUploadBase__highlight___2vU3G {
  color: var(--color-marinaBlue-90);
}

.FileUploadBase__hidden___3dClq {
  display: none;
  visibility: hidden;
}

/* --------------- */

/* Disabled Styles */

/* --------------- */

.FileUploadBase__label___3eV1x.FileUploadBase__disabled___3wH9L:hover, .FileUploadBase__dropbox___1NwQq.FileUploadBase__disabled___3wH9L:hover {
  cursor: default;
}

.FileUploadBase__label___3eV1x.FileUploadBase__disabled___3wH9L:hover + .FileUploadBase__dropbox___1NwQq,
.FileUploadBase__dropbox___1NwQq.FileUploadBase__disabled___3wH9L:hover {
  background-color: transparent;
}

.FileUploadBase__dropbox___1NwQq.FileUploadBase__disabled___3wH9L {
  opacity: 0.3;
}

.FileUploadBase__footer___10WqP {
  color: var(--colorRefTextMuted);
  margin-top: 4px;
}
