.index__ticker___AIXbn {
  display: flex;
  align-items: center;
}

.index__positive___1E1g9 {
  color: var(--color-algaeGreen-50);
  fill: var(--color-algaeGreen-50);
}

.index__negative___2KWRK {
  color: var(--color-lobsterRed-50);
  fill: var(--color-lobsterRed-50);
}

.index__tickerSign___qUtzY {
  margin-right: 0.5rem;
}

.index__arrowIcon___1zjFM {
  width: 16px;
  height: 16px;
}
