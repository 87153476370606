/* Breadcrumbs wrapper */

.index__wrapper___3QyFA {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Breadcrumb */

.index__breadcrumb___1DWBt {
  display: flex;
  align-items: center;
  color: var(--color-marinaBlue-70);
}

.index__activeBreadcrumb___2XfhY {
  color: var(--color-marinaBlue-90);
}

/* Text of the breadcrumb */

.index__text___MkQZ5 {
  line-height: 1.5rem;
  cursor: pointer;
  opacity: 0.5;
}

.index__text___MkQZ5:hover,
  .index__text___MkQZ5:focus,
  .index__text___MkQZ5:focus-within {
  opacity: 0.75;
}

.index__text___MkQZ5:active {
  opacity: 1;
}

.index__text___MkQZ5 a,
  .index__text___MkQZ5 a:hover,
  .index__text___MkQZ5 a:focus,
  .index__text___MkQZ5 a:focus-within,
  .index__text___MkQZ5 a:active {
  text-decoration: none;
  color: inherit;
}

.index__activeBreadcrumb___2XfhY > .index__text___MkQZ5 {
  cursor: default;
  opacity: 1;
}

.index__activeBreadcrumb___2XfhY > .index__text___MkQZ5:hover,
  .index__activeBreadcrumb___2XfhY > .index__text___MkQZ5:focus,
  .index__activeBreadcrumb___2XfhY > .index__text___MkQZ5:focus-within,
  .index__activeBreadcrumb___2XfhY > .index__text___MkQZ5:active {
  opacity: 1;
}

/* Chevron icon of the breadcrumb */

.index__icon___1VdmS {
  width: 1rem;
  height: 1rem;
  margin-left: 4px;
  margin-right: 4px;
  fill: currentColor;
  opacity: 0.5;
}

.index__activeBreadcrumb___2XfhY > .index__icon___1VdmS {
  opacity: 1;
}
