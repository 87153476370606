.index__containerIcon___r3T4D {
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s linear;
}

.index__containerIcon___r3T4D:before {
  content: '';
  position: absolute;
  background-color: var(--color-marinaBlue-90);
  border-radius: 9999px;
  opacity: 0;
  z-index: 0;
  --transform-scale-x: .5;
  --transform-scale-y: .5;
  transition-property: all;
  transition-duration: 100ms;
  height: 150%;
  width: 150%;
  top: -6px;
}

.index__containerIcon___r3T4D.index__default___3Woq- {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.index__containerIcon___r3T4D.index__checked___2iOpj,
  .index__containerIcon___r3T4D.index__indeterminate___2vpqL {
  color: var(--color-marinaBlue-50);
}

.index__containerIcon___r3T4D.index__disabled___34nmm {
  opacity: 0.3;
  cursor: not-allowed;
  color: var(--color-marinaBlue-50);
}

.index__containerIcon___r3T4D.index__error___3_ZxS {
  color: var(--color-lobsterRed-60);
}

.index__containerIcon___r3T4D:not(.index__disabled___34nmm):not(.index__error___3_ZxS):focus-within {
  outline: 1px solid white;
  border-radius: 4px;
  outline-offset: 1px;
}

.index__containerIcon___r3T4D:not(.index__disabled___34nmm):not(.index__error___3_ZxS):hover,
    .index__containerIcon___r3T4D:not(.index__disabled___34nmm):not(.index__error___3_ZxS):focus-within {
  color: var(--color-marinaBlue-50);
}

.index__containerIcon___r3T4D:not(.index__disabled___34nmm):not(.index__error___3_ZxS):active {
  color: var(--color-marinaBlue-40);
}

.index__containerIcon___r3T4D:not(.index__disabled___34nmm):not(.index__error___3_ZxS):focus-within:before, .index__containerIcon___r3T4D:not(.index__disabled___34nmm):not(.index__error___3_ZxS):active:before {
  opacity: 0.1;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
}

.index__containerIcon___r3T4D + label {
  margin-left: 0.5rem;
}

.index__containerIcon___r3T4D > svg {
  fill: currentColor;
}

.index__input___2_fjj {
  cursor: inherit;
  z-index: 1;
  /* ant-d adds styles that are leaky and override our width and height
  when the actual checkbox is in a form */
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
}
