.UploadedFile__fileWrapper___rloyT {
  position: relative;
  padding-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 140%;
  list-style-type: none;
}

.UploadedFile__closeButton___3FGPT {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.UploadedFile__closeButton___3FGPT svg {
  height: 1.5rem;
  width: 1.5rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  fill: currentColor;
}

.UploadedFile__filename___3fZaO {
  margin-bottom: 4px;
  color: var(--color-marinaBlue-90);
}

.UploadedFile__status___1G5sy {
  display: flex;
  color: var(--color-algaeGreen-60);
}

.UploadedFile__status___1G5sy.UploadedFile__loading___1HU6F {
  flex-direction: column;
  color: var(--color-marinaBlue-60);
}

.UploadedFile__status___1G5sy.UploadedFile__error____P_lv {
  color: var(--color-lobsterRed-60);
}

.UploadedFile__checkIcon___1zYaY {
  /* In the design the bottom of the check mark touches the bottom of the font but the tails of letters (like lowercase p) goes below the checkmark */
  height: 11px;
  margin-top: 2px;
  margin-left: 0.5rem;
  fill: currentColor;
}

.UploadedFile__bar___27gtM {
  margin-top: 0.5rem;
}
