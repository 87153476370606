.styles__actionContainer___2u-vd {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.styles__progressControls___21BtF {
  display: flex;
  grid-gap: 8px;
  grid-gap: 8px;
  gap: 8px;
}

.styles__modalFooter___Liqo6 {
  padding: 16px;
  border-top: 1px solid rgba(var(--color-basaltGray-90-rgb), 0.2);
}
