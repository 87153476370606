.index__multiValueRemoveContainer___1synj {
  display: flex;
  align-items: center;
  color: var(--color-marinaBlue-70);
}

.index__multiValueRemoveContainer___1synj:hover {
  color: var(--color-marinaBlue-90);
  background-color: transparent !important;
  color: var(--color-marinaBlue-90) !important;
}

.index__multiValueRemoveContainer___1synj > svg {
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.2rem;
  transition: all 0.2s;
  fill: currentColor;
}
