.styles__arrayControls___36Aqm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.styles__controlBtn___2mmW5 {
  fill: var(--color-marinaBlue-90);
  height: 24px;
  width: 24px;
}

.styles__addRemoveBtnContainer___1_7kA {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.styles__formContainer___3PdF5 {
  flex-grow: 1;
}

.styles__defaultAdd___7vX6M {
  text-align: right;
}

.styles__error___JJHAu {
  color: var(--color-lobsterRed-60);
}

.styles__emptyMessage___2ibIz {
  color: var(--color-marinaBlue-90);
  margin-bottom: 8px;
  text-align: center;
}
