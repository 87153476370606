.index__closeIcon___1aNy6 {
  width: 16px;
  height: 16px;
  fill: var(--color-marinaBlue-90);
}

.index__mask___3ChQm {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
  pointer-events: auto;
}

.index__drawerWrapper___1VV4d {
  top: 0;
  bottom: 0;
  box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08),
    -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
  position: fixed;
  transition: all 0.45s;
  width: 500px;
}

.index__drawerWrapperClosed___2-UJk {
  right: -100%;
}

.index__drawerWrapperOpen___1HNdk {
  right: 0px;
}

.index__contentWrapper___1fg1a {
  background-color: var(--color-basaltGray-10);
  width: 100%;
  height: 100%;
  overflow: auto;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
}

.index__drawerContainer___3EEqL {
  width: 100%;
  height: 100%;
}

.index__drawerHeader___1uVsq {
  display: flex;
  flex: 0;
  align-items: center;
  margin: 40px 32px 16px 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: space-between;
  padding-bottom: 8px;
}

.index__bodyContent___3s7Lu {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 0px 32px 32px;
  overflow: auto;
}

.index__footerContent___3uPJ_ {
  /* justify-self: flex-end; */
  padding: 16px 32px;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;
  flex-grow: 0;
  align-self: flex-end;
}

.index__closeWrapper___26dKZ {
  align-self: flex-start;
}

.index__noScroll___2Spoy {
  overflow: hidden;
}