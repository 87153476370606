.index__tooltip____miyh {
  max-width: 320px;
  width: -webkit-max-content;
  width: max-content;
  background-color: var(--color-marinaBlue-10);
  padding: 8px 16px;
  border-radius: 4px;
}

.index__tooltipText___2D9DX {
  color: var(--color-marinaBlue-90);
}
