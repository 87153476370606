.index__root___1mQh- {
  display: flex;
  align-items: flex-start;
  flex: 0 0 auto;
}

.index__closeButton___3ekYG {
  position: relative;
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-marinaBlue-90);
}

.index__closeButton___3ekYG:before {
  content: '';
  display: block;
  position: absolute;
  background-color: rgba(var(--color-marinaBlue-90-rgb), 0.2);
  height: 100%;
  width: 1px;
  left: -4px;
  top: 0;
}

.index__closeIcon___1cyWy {
  fill: var(--color-marinaBlue-90);
}
