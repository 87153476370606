.index__loader___1t32F {
  color: var(--color-marinaBlue-70);
}

/* https://cssfx.netlify.app/ */

.index__loader___1t32F svg {
  max-width: 100%;
  width: 3.75em;
  transform-origin: center;
  animation: index__rotate___bPngH 2s linear infinite;
}

/* From CSSFX */

.index__loader___1t32F circle {
  stroke: currentColor;
  fill: none;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: index__dash___3CFve 1.5s ease-in-out infinite;
}

/* Center self within a div */

.index__centered___35vGG {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

@keyframes index__rotate___bPngH {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes index__dash___3CFve {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}
